'use client';

import { createGlobalStyle } from 'styled-components';
import carousel from 'legacy-mise-ui/styles/carousel';
import globalStyle from 'legacy-mise-ui/styles/global';
import { favoritesWidget, reset, globalFonts } from '../config/css';
import SuperMiseStyles from '@americastestkitchen/mise/styles/mise.scss';

const GlobalStyle = createGlobalStyle`${reset}${globalStyle}${carousel}${favoritesWidget}${SuperMiseStyles}${globalFonts}`;

export default GlobalStyle;
