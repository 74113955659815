/* eslint-disable no-unused-vars */
import NewsletterPopoverWidget from './popover-widget';
import NewsletterInlineWidget from './inline-widget';
import widgetUtils from '../FavoritesWidget/widgetUtils/utils';
import parseUserClient from '../Mixpanel/parseUserClient';

/*
  All keys within each entry are required!!!

  - newsletter_id (PK from the `hunk.newsletters` table. See https://www.americastestkitchen.com/admin/newsletters)
  - triggered_send (Corresponds to an ExactTarget email template)

  Mixpanel event properties:
  -type
  -location
*/
const newsletterMap = {
  dinnertonight: {
    incode: 'MZDRMEC1A',
    newsletter_id: 16,
    triggered_send: 'CC_Dinner_Tonight_Confirmation',
    type: "Cook's Country Dinner Tonight",
  },
  ntk: {
    incode: 'MZDRMNK1A',
    newsletter_id: 3,
    triggered_send: 'ConfirmationNTK_Email',
    type: 'Notes from the Test Kitchen',
  },
  shortcutstodinner: {
    incode: 'MZDCZDS00',
    de_name: 'Journey_Jack_Start',
    type: 'Shortcuts To Dinner',
    api: '/newsletter/journey_event',
    event_definition_key: 'APIEvent-74066447-d82f-56f3-b827-540199d280c6',
  },
};

const disablePopoverWidget =
  process.env.NEWSLETTER_POPOVER_WIDGET_DISABLED === 'true';

const overrides = {
  '10-weeknight-heroes-that-deserve-a-spot-in-your-recipe-rotation':
    'shortcutstodinner',
  '10-ingredients-30-minutes': 'shortcutstodinner',
  '25-chicken-breast-recipes-that-arent-boring': 'shortcutstodinner',
  '20-recipes-that-use-5-ingredients-or-less': 'shortcutstodinner',
  '25-fun-family-friendly-recipes-that-you-can-make-together':
    'shortcutstodinner',
  'perfect-recipes-to-cook-when-you-dont-feel-like-cooking':
    'shortcutstodinner',
  'one-pan-meals-you-can-prepare-in-a-skillet': 'shortcutstodinner',
  '20-recipes-that-use-the-canned-beans-in-your-pantry': 'shortcutstodinner',
  '20-recipes-that-will-use-up-the-ground-meat-in-your-freezer':
    'shortcutstodinner',
  '25-slow-cooker-recipes-you-can-make-while-working-from-home':
    'shortcutstodinner',
  '25-vegetarian-recipes-that-are-made-with-ingredients-you-may-already-have':
    'shortcutstodinner',
  '20-egg-recipes-for-breakfast-and-beyond': 'shortcutstodinner',
  '20-surprisingly-simple-seafood-recipes': 'shortcutstodinner',
  '25-pastas-you-can-make-with-10-or-fewer-ingredients': 'shortcutstodinner',
  '20-weeknight-friendly-meals-that-use-the-shrimp-in-your-freezer':
    'shortcutstodinner',
  '25-of-your-favorite-comfort-foods-made-vegan': 'shortcutstodinner',
  '20-recipes-where-mushrooms-are-the-star': 'shortcutstodinner',
  '20-rice-recipes-from-around-the-world': 'shortcutstodinner',
  '25-diy-homemade-condiments-and-spice-mixes-you-can-make-at-home':
    'shortcutstodinner',
  'the-recipes-from-julia-at-home': 'ntk',
  'the-recipes-from-atk-presents': 'ntk',
};
let newsletterWidgetApp = null;

export const newsletterOnReady = (fn) => {
  try {
    const evt = widgetUtils.getCookie('auth_token')
      ? 'user:get'
      : 'dry:dom:loaded';
    dry.events.subscribe(evt, fn);
  } catch (err) {
    if (document.readyState !== 'loading') fn();
    else document.addEventListener('DOMContentLoaded', fn);
  }
};

const getDocumentData = (pathname) => {
  const splitPath = pathname.substring().toLowerCase().split('/');
  const lastItem = splitPath.slice(-1);
  const documentId = lastItem[0].split('-', 1)[0];
  const documentTitle = lastItem[0].split('-').slice(1).join(' ');
  return { documentId, documentTitle };
};

export const runNewsletterWidget = (userData) => {
  const user = userData === true ? null : userData;
  const pathname = document.location.href;
  const { documentId, documentTitle } = getDocumentData(pathname);
  const userSegment = parseUserClient();
  const segment = userSegment?.segment;
  const now = new Date();
  const hr = now.getHours();
  // note: this next line is temp/initial value...will be more dynamic in future
  if (typeof NewsletterPopoverWidget !== 'undefined' && !newsletterWidgetApp) {
    const newsletterType = hr > 12 && hr < 19 ? 'dinnertonight' : 'ntk';
    const appEl = document.querySelector('body');
    const newsletterOptions = newsletterMap[newsletterType];
    const popoverOptions = {
      context: 'popover',
      location: 'recipe pinpop',
    };
    const options = Object.assign(popoverOptions, newsletterOptions);
    if (disablePopoverWidget) {
      setTimeout(() => {
        newsletterWidgetApp = new NewsletterPopoverWidget(
          user,
          appEl,
          newsletterType,
          options,
        );
      }, 250);
    }
  }
  const inlineForms = document.querySelectorAll(
    '.newsletter-widget-inline-target',
  );
  if (typeof NewsletterInlineWidget !== 'undefined' && inlineForms.length > 0) {
    const inlineWidgets = [];
    const inlineOptions = {
      mixpanel_attributes: {
        email_capture_type: 'newsletterBelt',
        current_url: document.location.href || '',
      },
      context: 'inline',
      location: 'collections inline',
      sfmc_params: {
        doc_id: documentId || '',
        doc_title: documentTitle || '',
        doc_type: 'collection',
        doc_url: document.location.href || '',
        email_capture_type: 'newsletterBelt',
        user_segment: segment,
      },
    };
    [].slice.call(inlineForms).forEach((widgetEl) => {
      if (!widgetEl.dataset.widgetized) {
        const newsletterType =
          overrides[widgetEl.dataset.slug] || 'dinnertonight';
        const newsletterOptions = newsletterMap[newsletterType];
        const options = Object.assign(inlineOptions, newsletterOptions);
        inlineWidgets.push(
          new NewsletterInlineWidget(user, widgetEl, newsletterType, options),
        );
        widgetEl.dataset.widgetized = true;
      }
    });
  }
};
