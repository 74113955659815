export const FF_V6_LOGIN = process.env.NEXT_PUBLIC_FF_V6_LOGIN
  ? process.env.NEXT_PUBLIC_FF_V6_LOGIN === 'true'
  : false;

export const FF_MISE_REVIEWS = process.env.NEXT_PUBLIC_FF_MISE_REVIEWS
  ? process.env.NEXT_PUBLIC_FF_MISE_REVIEWS === 'true'
  : false;

export const FF_REMOVE_ARTICLE_COMMENTS = process.env
  .NEXT_PUBLIC_FF_REMOVE_ARTICLE_COMMENTS
  ? process.env.NEXT_PUBLIC_FF_REMOVE_ARTICLE_COMMENTS === 'true'
  : false;

export const FF_PROOF_SPONSORS = process.env.NEXT_PUBLIC_FF_PROOF_SPONSORS
  ? process.env.NEXT_PUBLIC_FF_PROOF_SPONSORS
  : '';

export const FF_REMOVE_REVIEW_COMMENTS = process.env
  .NEXT_PUBLIC_FF_REMOVE_REVIEW_COMMENTS
  ? process.env.NEXT_PUBLIC_FF_REMOVE_REVIEW_COMMENTS
  : '';

export const FF_ATK25 = process.env.NEXT_PUBLIC_FF_ATK25
  ? process.env.NEXT_PUBLIC_FF_ATK25 === 'true'
  : false;
