import classNames from 'classnames/bind';

import HeaderPlayIcon from '../HeaderPlayIcon/HeaderPlayIcon';
import HighlightCta from '@americastestkitchen/mise/components/partials/CTAs/HighlightCta/HighlightCta';
import UnderlineLink from '@americastestkitchen/mise/components/partials/Links/UnderlineLink/UnderlineLink';
import { useDrawer } from 'lib/hooks/useDrawer';
import { UtilityIcon } from '@americastestkitchen/mise/components/partials/Icons/UtilityIcon/UtilityIcon';
import { GlobalHeaderLink } from '../shared/types';

import styles from './documentNav.module.scss';
import Typography from '@americastestkitchen/mise/components/partials/Typography/Typography';

const cx = classNames.bind(styles);

type DocumentNavLink = GlobalHeaderLink & {
  showPlayIcon?: boolean;
  subLinks?: {
    href: string;
    isActive: boolean;
    label: string;
  }[];
};

export type DocumentNavProps = {
  links: DocumentNavLink[];
};

const megaMenuItems = [
  {
    imgSrc:
      'https://res.cloudinary.com/hksqkdlah/image/upload/v1/play-mega-menu/play-mega-menu-atk',
    title: "America's Test Kitchen",
    videoHref: '/episodes/latest',
  },
  {
    imgSrc:
      'https://res.cloudinary.com/hksqkdlah/image/upload/v1/play-mega-menu/play-mega-menu-cco',
    title: "Cook's Country",
    videoHref: 'https://www.cookscountry.com/episodes/latest',
  },
  {
    imgSrc:
      'https://res.cloudinary.com/hksqkdlah/image/upload/v1/play-mega-menu/play-mega-menu-cio',
    title: "Cook's Illustrated",
    videoHref: 'https://www.cooksillustrated.com/videos/latest',
  },
];

const MegaMenuController = ({
  href,
  linkClassNames,
  label,
}: {
  href: string;
  linkClassNames: string;
  label: string;
}) => {
  const { drawerProps, expanded, triggerProps } = useDrawer('mega-menu');
  return (
    <div className={styles.megaMenuTriggerWrapper}>
      <a className={`${linkClassNames}`} href={href}>
        {label}
      </a>
      <button
        aria-label={`${expanded ? 'Close' : 'Open'} ${label} Mega Menu`}
        className={`${styles.megaMenuTrigger} mega-menu-button`}
        data-mega-menu-type={label.toLowerCase()}
        {...triggerProps}
      >
        <div className={styles.chevronWrapper}>
          <UtilityIcon type="downChevron" />
        </div>
      </button>
      <div
        className={
          expanded ? styles.megaMenuWrapper : styles.hideMegaMenuContent
        }
        {...drawerProps}
      >
        <div className={styles.playMega}>
          <div className={styles.playMegaInner}>
            <div className={styles.playMegaItems}>
              {megaMenuItems.map((item) => {
                const videoOrEpisode =
                  item.title === "Cook's Illustrated" ? 'video' : 'episode';
                return (
                  <div
                    className={styles.playMegaItem}
                    key={`${item.title}-menu-item`}
                  >
                    <img
                      alt=""
                      className={styles.playMegaImage}
                      src={item.imgSrc}
                    />
                    <div>
                      <Typography
                        className={styles.playMegaItemHeader}
                        elementTag="h2"
                        type="open"
                        size="lg"
                        weight="bold"
                      >
                        {item.title}
                      </Typography>
                      <UnderlineLink
                        href={item.videoHref}
                        className={`${styles.playMegaLink} mega-menu-sub-category`}
                        data-subcategory={item.title}
                        data-type={`watch ${videoOrEpisode}`}
                      >
                        Watch latest {videoOrEpisode} &gt;
                      </UnderlineLink>
                      <UnderlineLink
                        href="/recipes"
                        className={`${styles.playMegaLink} mega-menu-sub-category`}
                        data-subcategory={item.title}
                        data-type="get recipes"
                      >
                        Get the Recipes &gt;
                      </UnderlineLink>
                    </div>
                  </div>
                );
              })}
            </div>
            <HighlightCta as="a" displayType="block" href="/shows">
              Browse All Shows
            </HighlightCta>
          </div>
        </div>
      </div>
    </div>
  );
};

const DocumentNav = ({ links }: DocumentNavProps) => {
  return (
    <div className={styles.wrapper} data-testid="document-nav">
      <nav className={styles.nav}>
        <ul className={styles.links}>
          {links.map((link) => {
            const linkClassNames = cx(styles.link, 'site-nav-link', {
              [styles.linkActive]: link.isActive,
              [styles.withSubLinks]: link.subLinks,
            });
            return (
              <li className={styles.subLinksWrapper} key={link.href}>
                {link.label === 'Shows' ? (
                  <MegaMenuController
                    href={link.href}
                    label={link.label}
                    linkClassNames={linkClassNames}
                  />
                ) : (
                  <a className={linkClassNames} href={link.href}>
                    {link.showPlayIcon && <HeaderPlayIcon />}
                    {link.label}
                  </a>
                )}
                {link.subLinks ? (
                  <ul className={styles.subLinksList}>
                    {link.subLinks.map((subLink) => (
                      <li key={subLink.href}>
                        <a
                          className={cx(styles.subLink, 'site-nav-link', {
                            [styles.subLinkActive]: subLink.isActive,
                          })}
                          href={subLink.href}
                        >
                          <Typography type="dense" weight="bold">
                            {subLink.label}
                          </Typography>
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default DocumentNav;
