import classNames from 'classnames';

import HighlightCta from '@americastestkitchen/mise/components/partials/CTAs/HighlightCta/HighlightCta';

import styles from './userCtas.module.scss';
import Typography from '@americastestkitchen/mise/components/partials/Typography/Typography';

const cx = classNames.bind(styles);

export type UserCtasProps = {
  links: {
    href: string;
    label: string;
    target?: '_blank' | undefined;
    className?: string;
  }[];
  tvListingsUrl?: string;
};

const UserCtas = ({ links }: UserCtasProps) => {
  const [linkOne, linkTwo, linkThree, linkFour] = links;
  return (
    <div
      className={`${styles.wrapper} global-header-user-ctas`}
      data-testid="global-header-user-ctas"
    >
      <div className={styles.links}>
        <div className={styles.linkSet}>
          {linkOne && (
            <HighlightCta
              as="a"
              displayType="block"
              href={linkOne.href}
              target={linkOne.target}
              typography={{ type: 'caps', size: 'sm', weight: 'bold' }}
            >
              {linkOne.label}
            </HighlightCta>
          )}
          {linkThree && (
            <a
              className={cx(styles.secondaryLink, linkThree.className)}
              href={linkThree.href}
              target={linkThree.target}
            >
              <Typography type="open" weight="bold">
                {linkThree.label}
              </Typography>
            </a>
          )}
        </div>
        <div className={styles.linkSet}>
          {linkTwo && (
            <HighlightCta
              as="a"
              displayType="block"
              href={linkTwo.href}
              target={linkTwo.target}
              typography={{ type: 'caps', size: 'sm', weight: 'bold' }}
            >
              {linkTwo.label}
            </HighlightCta>
          )}
          {linkFour && (
            <a
              className={cx(styles.secondaryLink, linkFour.className)}
              href={linkFour.href}
              target={linkFour.target}
            >
              {linkFour.label}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCtas;
