import classNames from 'classnames/bind';

import LinkList from '../LinkList/LinkList';
import { UtilityIcon } from '@americastestkitchen/mise/components/partials/Icons/UtilityIcon/UtilityIcon';
import { useDrawer } from 'lib/hooks/useDrawer';

import styles from './hamburgerMenu.module.scss';
import { UserCtasProps } from '../UserCtas/UserCtas';
import { GlobalHeaderLink, SiteNavLink } from '../shared/types';
import { RefObject, useEffect } from 'react';

const cx = classNames.bind(styles);

export type HamburgerMenuProps = {
  headerLinks: SiteNavLink[];
  list: GlobalHeaderLink[];
  magazineAd?: {
    altText: string;
    href: string;
    imgSrc: string;
  };
  userCtas: UserCtasProps;
  toggleEffect: (
    hamburgerButton?: RefObject<HTMLButtonElement>,
    hamburgerContent?: RefObject<HTMLDivElement>,
  ) => void;
};

const HamburgerMenu = ({
  headerLinks,
  list,
  magazineAd,
  toggleEffect,
  userCtas,
}: HamburgerMenuProps) => {
  const { drawerProps, expanded, triggerProps } = useDrawer(
    'hamburger-menu-content',
  );

  const wrapperClassNames = cx(styles.outer, 'global-header-hamburger');
  const buttonClassNames = cx(styles.button, 'hamburger-menu-trigger');
  const drawerClassNames = cx(styles.wrapper, 'hamburger-menu-content', {
    [styles.hideDrawer]: !expanded,
  });

  useEffect(() => {
    toggleEffect(triggerProps.ref, drawerProps.ref);
  }, [drawerProps.ref, expanded, toggleEffect, triggerProps.ref]);

  return (
    <>
      <div
        aria-hidden="true"
        className={expanded ? styles.backgroundFill : ''}
      ></div>
      <div className={wrapperClassNames} data-testid="hamburger-menu">
        <button
          {...triggerProps}
          aria-label={`${expanded ? 'Close' : 'Open'} Hamburger Menu`}
          className={buttonClassNames}
        >
          {expanded ? (
            <UtilityIcon type="close" />
          ) : (
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              viewBox="0 0 24 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ pointerEvents: 'none' }}
            >
              <path
                d="M1 1H23"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="square"
              />
              <path
                d="M1 6.8667H23"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="square"
              />
              <path
                d="M1 12.7333H23"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="square"
              />
            </svg>
          )}
        </button>
        <div className={drawerClassNames} {...drawerProps}>
          <div className={styles.ctaWrapper}>
            <ul className={styles.ctaList}>
              {userCtas?.links?.map((userCta, index) => (
                <li
                  className={`${styles.ctaLink} ${
                    index > 1
                      ? styles.secondaryLinkText
                      : styles.primaryLinkText
                  }`}
                  key={`hamburger-menu-user-cta--${userCta.href}`}
                >
                  <a href={userCta.href} target={userCta.target}>
                    {userCta.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <LinkList
            ariaLabel=""
            list={list}
            magazineAd={magazineAd}
            includeAnalytics
            textAlignment="left"
          />
          <nav className={styles.brandListWrapper}>
            <ul className={styles.brandList}>
              {headerLinks?.map((link) => (
                <li
                  key={`hamburger-link-${link.href}`}
                  className={styles.brandListItem}
                >
                  <a href={link.href} target={link.target ?? '_self'}>
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default HamburgerMenu;
